import React, {Component} from 'react';

class Footer extends Component {
  render() {
    return (
      <footer>
        <p>&copy; Created using React by Jessica Jenkins 2021</p>
      </footer>
    );
  }
}

export default Footer;